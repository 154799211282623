import { Container } from "react-bootstrap";
import Header from "../Components/Header";

const ContactEN = () => {
  return (
    <>
      <Header />
      <Container style={{ paddingTop: 32 }}>
        <h1>Contate-Nos</h1>
        <p style={{ paddingBottom: 24 }}>Se você tiver alguma dúvida ou sugestão sobre o app 52 Semanas, não hesite em me contatar em <a href="mailto:contato@crjlab.com">contato@crjlab.com</a>.</p>
      </Container>
    </>
  );
}

export default ContactEN;