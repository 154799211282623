import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import appImage from "../Assets/img/app_mockup_landing3.png";
import LogoImg from '../Assets/img/app_icon_min.png';
import GoogleBadge from "../Assets/img/google_badge.png";
import AppleBadge from "../Assets/img/apple_badge.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <PageBackground className="align-items-center">
      <Col>
        <Container className="p-5">
          <Row className="align-items-center g-0 mb-5">
            <Col className="text-center p-5">
              <AppLanding src={appImage} />
            </Col>
            <Col className="p-5">
              <Row className='align-items-center mb-5'>
                <Col xs="auto">
                  <Logo src={LogoImg} />
                </Col>
                <Col xs="auto">
                  <AppTitle>52 Weeks Challenge</AppTitle>
                </Col>
              </Row>
              <AppH1>Achieve your goals in 52, 104, or 156 weeks.</AppH1>
              <AppP className="mb-5">
                It's a simple method that encourages people to save money every week. In this method, the investor must save a certain amount of money every week with the ultimate goal of accumulating a value for the achievement of a goal.
              </AppP>
              <Row className='align-items-center mb-5 g-0'>
                <Col xs="auto" className="p-0">
                  <a href="https://apps.apple.com/us/app/52-semanas-poupe-dinheiro/id1602023599"><BadgeImg src={AppleBadge} alt="Download on the App Store"/></a>
                </Col>
                {/* <Col xs="auto" className="p-0">
                  <a href="#"><BadgeImg src={GoogleBadge} alt="Get it on Google Play" /></a>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-between p-5'>
            <MadeCol xs="12" md="auto" className="mb-3 text-center">Made with ❤️ in 🇧🇷 by Cloves R. Junior</MadeCol>
            <Col xs="12" md="auto" className="mb-3 text-center">
              <LinkText to="/privacy/en">Privacy Policy</LinkText>
            </Col>
            <Col xs="12" md="auto" className="mb-3 text-center">
              <LinkText to="/contact/en">Contact Us</LinkText>
            </Col>
          </Row>
        </Container>
      </Col>
    </PageBackground>
  );
}

const LinkText = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const MadeCol = styled(Col)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const BadgeImg = styled.img`
  height: 50px;
  margin-right: 8px;
  margin-top: 16px;
`;

const PageBackground = styled(Row)`
  background-color: #2d3436;
  min-height: 100vh;
`;

const AppLanding = styled.img`
  width: 250px;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 10px;
`;

const AppTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const AppH1 = styled.h1`
  color: white;
`;

const AppP = styled.p`
  color: white;
`;

export default Home;