import { Container } from "react-bootstrap";
import Header from "../Components/Header";

const PrivacyFR = () => {
  return (
    <>
      <Header />
      <Container style={{ paddingTop: 32 }}>
        <h1>Politique de confidentialité</h1>
        <p>Cloves Rodrigues Junior (crjlab) a créé l'application 52 Weeks en tant qu'application commerciale. Ce SERVICE est fourni par Cloves Rodrigues Junior (crjlab) et est destiné à être utilisé tel quel.</p>
        <p>Cette page est utilisée pour informer les visiteurs de mes politiques de collecte, d'utilisation et de divulgation des informations personnelles si quelqu'un décide d'utiliser mon service.</p>
        <p>Si vous choisissez d'utiliser mon service, vous acceptez la collecte et l'utilisation d'informations en relation avec cette politique. Les informations personnelles que je collecte sont utilisées pour fournir et améliorer le service. Je n'utiliserai ni ne partagerai vos informations avec qui que ce soit, sauf de la manière décrite dans la présente politique de confidentialité.</p>
        <p style={{ paddingBottom: 24 }}>Les termes utilisés dans la présente politique de confidentialité ont la même signification que dans nos conditions générales, qui sont accessibles à 52 semaines, sauf indication contraire dans la présente politique de confidentialité.</p>
        
        <h2>Collecte et utilisation des informations</h2>        
        <p>Pour une meilleure expérience, lors de l'utilisation de notre service, je peux vous demander de nous fournir certaines informations personnellement identifiables. Les informations que je demande seront conservées sur votre appareil et ne seront en aucun cas collectées par moi.</p>
        <p>L'application utilise des services tiers qui peuvent collecter des informations utilisées pour vous identifier.</p>
        <p>Lien vers la politique de confidentialité des fournisseurs de services tiers utilisés par l'application</p>
        <ul style={{ paddingBottom: 24, paddingTop: 24 }}>
          <li><a href="https://firebase.google.com/policies/analytics">Google Analytics pour Firebase</a></li>
        </ul>

        <h2>Données de journal</h2>
        <p style={{ paddingBottom: 24 }}>Je tiens à vous informer que chaque fois que vous utilisez mon service, en cas d'erreur dans l'application, je collecte des données et des informations (via des produits tiers) sur votre téléphone, appelées Log Data. Ces données de journal peuvent inclure des informations telles que l'adresse de protocole Internet ("IP") de votre appareil, le nom de l'appareil, la version du système d'exploitation, la configuration de l'application lors de l'utilisation de mon service, l'heure et la date de votre utilisation du service et d'autres statistiques.</p>
        
        <h2>Cookies</h2>
        <p>Les cookies sont des fichiers contenant une petite quantité de données qui sont couramment utilisés comme identifiants uniques anonymes. Ceux-ci sont envoyés à votre navigateur à partir des sites Web que vous visitez et sont stockés dans la mémoire interne de votre appareil.</p>
        <p style={{ paddingBottom: 24 }}>Ce Service n'utilise pas explicitement ces « cookies ». Cependant, l'application peut utiliser du code tiers et des bibliothèques qui utilisent des "cookies" pour collecter des informations et améliorer leurs services. Vous avez la possibilité d'accepter ou de refuser ces cookies et de savoir quand un cookie est envoyé sur votre appareil. Si vous choisissez de refuser nos cookies, vous ne pourrez peut-être pas utiliser certaines parties de ce service.</p>
        
        <h2>Les fournisseurs de services</h2>
        <p>Je peux faire appel à des sociétés tierces et à des particuliers pour les raisons suivantes:</p>
        <ul style={{ paddingTop: 24 }}>
          <li>Pour faciliter notre Service;</li>
          <li>Pour fournir le Service en notre nom;</li>
          <li>Pour effectuer des services liés au Service; ou</li>
          <li>Pour nous aider à analyser comment notre Service est utilisé.</li>
        </ul>
        <p style={{ paddingBottom: 24 }}>Je souhaite informer les utilisateurs de ce service que ces tiers ont accès à leurs informations personnelles. La raison est d'accomplir les tâches qui leur sont confiées en notre nom. Cependant, ils sont tenus de ne pas divulguer ou utiliser les informations à d'autres fins.</p>
        
        <h2>Sécurité</h2>
        <p style={{ paddingBottom: 24 }}>J'apprécie votre confiance en nous fournissant vos informations personnelles, nous nous efforçons donc d'utiliser des moyens commercialement acceptables pour les protéger. Mais rappelez-vous qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sûre et fiable à 100 %, et je ne peux pas garantir sa sécurité absolue.</p>
        
        <h2>Liens vers d'autres sites</h2>
        <p style={{ paddingBottom: 24 }}>Ce Service peut contenir des liens vers d'autres sites. Si vous cliquez sur un lien tiers, vous serez dirigé vers ce site. Notez que ces sites externes ne sont pas exploités par moi. Par conséquent, je vous conseille vivement de consulter la politique de confidentialité de ces sites Web. Je n'ai aucun contrôle et n'assume aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites ou services tiers.</p>
        
        <h2>Confidentialité des enfants</h2>
        <p style={{ paddingBottom: 24 }}>Ces Services ne s'adressent à personne de moins de 13 ans. Je ne collecte pas sciemment d'informations personnellement identifiables auprès d'enfants de moins de 13 ans. Dans le cas où je découvre qu'un enfant de moins de 13 ans m'a fourni des informations personnelles, je les supprime immédiatement de nos serveurs. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez me contacter afin que je puisse faire les actions nécessaires.</p>
        
        <h2>Modifications de cette politique de confidentialité</h2>
        <p>Je peux mettre à jour notre politique de confidentialité de temps à autre. Ainsi, il vous est conseillé de consulter cette page périodiquement pour tout changement. Je vous informerai de tout changement en publiant la nouvelle politique de confidentialité sur cette page.</p>
        <p style={{ paddingBottom: 24 }}>Cette politique est effective à partir du 2022-02-24</p>
        
        <h2>Nous contacter</h2>
        <p style={{ paddingBottom: 24 }}>Si vous avez des questions ou des suggestions concernant ma politique de confidentialité, n'hésitez pas à me contacter à contato@crjlab.com.</p>
      </Container>
    </>
  );
}

export default PrivacyFR;