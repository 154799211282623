import { Container } from "react-bootstrap";
import Header from "../Components/Header";

const ContactEN = () => {
  return (
    <>
      <Header />
      <Container style={{ paddingTop: 32 }}>
        <h1>Nous contacter</h1>
        <p style={{ paddingBottom: 24 }}>Si vous avez des questions ou des suggestions à propos de l'application 52 Semaines, n'hésitez pas à me contacter à <a href="mailto:contato@crjlab.com">contato@crjlab.com</a>.</p>
      </Container>
    </>
  );
}

export default ContactEN;