import { Container } from "react-bootstrap";
import Header from "../Components/Header";

const PrivacyPT = () => {
  return (
    <>
      <Header />
      <Container style={{ paddingTop: 32 }}>  
        <h1>Política de Privacidade</h1>
        <p>Cloves Rodrigues Junior (crjlab) construiu o aplicativo 52 Semanas como um aplicativo comercial. Este SERVIÇO é fornecido pela Cloves Rodrigues Junior (crjlab) e destina-se ao uso como está.</p>
        <p>Esta página é usada para informar os visitantes sobre minhas políticas de coleta, uso e divulgação de Informações Pessoais se alguém decidir usar meu Serviço.</p>
        <p>Se você optar por usar meu Serviço, concorda com a coleta e uso de informações em relação a esta política. As Informações Pessoais que eu coleto são usadas para fornecer e melhorar o Serviço. Não usarei ou compartilharei suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.</p>
        <p style={{ paddingBottom: 24 }}>Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que podem ser acessados em 52 semanas, a menos que definido de outra forma nesta Política de Privacidade.</p>

        <h2>Coleta e uso de informações</h2>        
        <p>Para uma melhor experiência, ao usar nosso Serviço, posso exigir que você nos forneça determinadas informações de identificação pessoal. As informações que eu solicitar serão retidas no seu dispositivo e não serão coletadas por mim de forma alguma.</p>
        <p>O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</p>
        <p>Link para a política de privacidade de provedores de serviços terceirizados usados pelo aplicativo</p>
        <ul style={{ paddingBottom: 24, paddingTop: 24 }}>
          <li><a href="https://firebase.google.com/policies/analytics">Google Analytics para Firebase</a></li>
        </ul>

        <h2>Dados de registro</h2>
        <p style={{ paddingBottom: 24 }}>Quero informar que sempre que você usar meu Serviço, em caso de erro no aplicativo, coleto dados e informações (através de produtos de terceiros) em seu telefone chamado Log Data. Esses Dados de Registro podem incluir informações como o endereço do Protocolo de Internet (“IP”) do seu dispositivo, nome do dispositivo, versão do sistema operacional, a configuração do aplicativo ao utilizar meu Serviço, a hora e a data de seu uso do serviço e outras estatísticas.</p>
        
        <h2>Cookies</h2>
        <p>Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.</p>
        <p style={{ paddingBottom: 24 }}>Este Serviço não usa esses “cookies” explicitamente. No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam “cookies” para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado ao seu dispositivo. Se você optar por recusar nossos cookies, talvez não consiga usar algumas partes deste Serviço.</p>

        <h2>Provedores de serviço</h2>
        <p>Posso empregar empresas e indivíduos terceirizados pelos seguintes motivos:</p>
        <ul style={{ paddingTop: 24 }}>
          <li>Para facilitar nosso Serviço;</li>
          <li>Para fornecer o Serviço em nosso nome;</li>
          <li>Para executar serviços relacionados ao Serviço; ou</li>
          <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
        </ul>
        <p style={{ paddingBottom: 24 }}>Quero informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outra finalidade.</p>

        <h2>Segurança</h2>
        <p style={{ paddingBottom: 24 }}>Eu valorizo sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos esforçando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico é 100% seguro e confiável, e não posso garantir sua segurança absoluta.</p>
        
        <h2>Links para outros sites</h2>
        <p style={{ paddingBottom: 24 }}>Este Serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado para esse site. Observe que esses sites externos não são operados por mim. Portanto, aconselho vivamente a rever a Política de Privacidade desses sites. Não tenho controle e não assumo responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros.</p>
 
        <h2>Privacidade das crianças</h2>
        <p style={{ paddingBottom: 24 }}>Esses Serviços não se dirigem a menores de 13 anos. Não coleto intencionalmente informações de identificação pessoal de crianças menores de 13 anos. No caso de eu descobrir que uma criança menor de 13 anos me forneceu informações pessoais, eu imediatamente as excluo de nossos servidores. Se você é pai ou responsável e está ciente de que seu filho nos forneceu informações pessoais, entre em contato comigo para que eu possa tomar as medidas necessárias.</p>
        
        <h2>Alterações nesta Política de Privacidade</h2>
        <p>Posso atualizar nossa Política de Privacidade de tempos em tempos. Assim, recomendamos que você revise esta página periodicamente para quaisquer alterações. Vou notificá-lo sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.</p>
        <p style={{ paddingBottom: 24 }}>Esta política está em vigor a partir de 24/02/2022</p>
        
        <h2>Contate-Nos</h2>
        <p style={{ paddingBottom: 24 }}>Se você tiver alguma dúvida ou sugestão sobre minha Política de Privacidade, não hesite em me contatar em contato@crjlab.com.</p>
      </Container>
    </>
  );
}

export default PrivacyPT;