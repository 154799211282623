import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import LogoImg from '../Assets/img/app_icon_min.png';

const Header = () => {
  return (
    <HeaderDiv className='d-flex align-items-center'>
      <Container className=''>
        <Row className='align-items-center'>
          <Col xs="auto">
            <Logo src={LogoImg} />
          </Col>
          <Col xs="auto">
            <HeaderTitle>52 Weeks Challenge</HeaderTitle>
          </Col>
        </Row>
      </Container>
    </HeaderDiv>
  );
}

const HeaderDiv = styled.div`
  width: 100%;
  height: 100px;
  background-color: #2d3436;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 10px;
`;

const HeaderTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: left;
  color: white;
`;

export default Header;