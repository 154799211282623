import {
  Routes,
  Route,
} from "react-router-dom";
import ContactPT from "./Pages/ContactPT";
import ContactEN from "./Pages/ContactEN";
import ContactES from "./Pages/ContactES";
import ContactFR from "./Pages/ContactFR";
import PrivacyEN from "./Pages/PrivacyEN";
import PrivacyES from "./Pages/PrivacyES";
import PrivacyFR from "./Pages/PrivacyFR";
import PrivacyPT from "./Pages/PrivacyPT";
import Home from "./Pages/Home";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/privacy/pt" element={<PrivacyPT />} />
      <Route path="/privacy/en" element={<PrivacyEN />} />
      <Route path="/privacy/es" element={<PrivacyES />} />
      <Route path="/privacy/fr" element={<PrivacyFR />} />
      <Route path="/contact/pt" element={<ContactPT />} />
      <Route path="/contact/en" element={<ContactEN />} />
      <Route path="/contact/es" element={<ContactES />} />
      <Route path="/contact/fr" element={<ContactFR />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default AppRouter;